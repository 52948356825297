<template>
  <ol>
    <li class="mb-2">
      <label class="d-block mb-2">Head image</label>
      <UploadOneImage :image="{ src: headImage1 }" @callback="image => onUpload(1, image)" />
    </li>
    <li class="mb-2">
      <label class="d-block mb-2">Head image</label>
      <UploadOneImage :image="{ src: headImage2 }" @callback="image => onUpload(2, image)" />
    </li>
    <li>
      <label class="d-block mb-2">Head image</label>
      <UploadOneImage :image="{ src: headImage3 }" @callback="image => onUpload(3, image)" />
    </li>
  </ol>
</template>

<script>
export default {
  props: ['themeSetting'],
  data() {
    return {
      headImage1: null,
      headImage2: null,
      headImage3: null,
    };
  },
  methods: {
    onUpload(order, image) {
      if (order === 1) this.headImage1 = image.src;
      else if (order === 2) this.headImage2 = image.src;
      else this.headImage3 = image.src;
      this.$emit('change', [this.headImage1, this.headImage2, this.headImage3]);
    },
    getData(value) {
      if (value?.pages?.homepage) {
        const heads = Object.values(value?.pages?.homepage)?.filter(item => item?.type === 'head-image');
        heads.forEach((head, index) => {
          if (index === 0) {
            this.headImage1 = head?.settings?.content?.desktop[0]?.image;
          } else if (index === 1) {
            this.headImage2 = head?.settings?.content?.desktop[0]?.image;
          } else if (index === 2) {
            this.headImage3 = head?.settings?.content?.desktop[0]?.image;
          }
        });
      }
    },
  },
  watch: {
    themeSetting: {
      handler(value) {
        this.getData(value);
      },
      deep: true,
    },
  },
  created() {
    this.getData(this.themeSetting);
  },
};
</script>
