<template>
  <v-row>
    <v-col cols="12" md="4">
      <h3>Store name</h3>
      <p>Choose a name for your store</p>
    </v-col>
    <v-col cols="12" md="8">
      <v-text-field v-model="$parent.general.storeDetail.name"></v-text-field>
    </v-col>
    <v-col cols="12" md="4">
      <h3>Logo</h3>
      <p>Upload Logo for your shop</p>
    </v-col>
    <v-col cols="12" md="8">
      <UploadOneImage :image="$parent.logoImage" @callback="getImageUpLoad" />
    </v-col>
  </v-row>
</template>
<script>
export default {
  methods: {
    getImageUpLoad(image) {
      this.$parent.logoImage = image;
      this.$parent.attributes.logoPath = image.src;
      this.$forceUpdate();
    },
  },
};
</script>
