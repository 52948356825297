<template>
  <div class="preferences__page">
    <v-row no-gutters>
      <v-col cols="12" class="page-header">
        <h1 class="page-title">Preferences</h1>
      </v-col>
      <v-col cols="12" v-if="isLoading">
        <SkeletonLoaders type="card-heading, list-item-three-line" />
      </v-col>
      <v-col cols="12" v-else>
        <v-row>
          <v-col cols="12">
            <Favicon />
            <v-divider class="my-5"></v-divider>
          </v-col>
          <v-col cols="12">
            <Logo />
            <v-divider class="my-5"></v-divider>
          </v-col>
          <v-col cols="12">
            <MetaDescription />
            <v-divider class="my-5"></v-divider>
          </v-col>
          <!-- <v-col cols="12">
            <Criteo />
            <v-divider class="my-5"></v-divider>
          </v-col>
          <v-col cols="12">
            <GoogleAnalytic />
            <v-divider class="my-5"></v-divider>
          </v-col>
          <v-col cols="12">
            <GoogleAdsTracking
              :data="{
                googleAdsConversionTrack: attributes.googleAdsConversionTrack,
                googleAdsConversionLabel: attributes.googleAdsConversionLabel,
              }"
              ref="google-ads-tracking"
            />
            <v-divider class="my-5"></v-divider>
          </v-col>
          <v-col cols="12">
            <FacebookPixel @removePixel="removePixel" @addPixel="addPixel" :facebookPixel="facebookPixel" />
            <v-divider class="my-5"></v-divider>
          </v-col>
          <v-col cols="12">
            <TiktokIdPixel @removeTikTokPixel="removeTikTokPixel" @addTikTokPixel="addTikTokPixel" :tiktok="tiktok" />
            <v-divider class="my-5"></v-divider>
          </v-col>
          <v-col cols="12">
            <PinterestBing />
            <v-divider class="my-5"></v-divider>
          </v-col>
          <v-col cols="12">
            <Klaviyo />
            <v-divider class="my-5"></v-divider>
          </v-col> -->
          <v-col cols="12">
            <Scripts />
            <v-divider class="my-5"></v-divider>
          </v-col>
          <!-- <v-col cols="12">
            <PasswordProtection />
            <v-divider class="my-5"></v-divider>
          </v-col>
          <v-col cols="12">
            <PersonalizationPreview />
            <v-divider class="my-5"></v-divider>
          </v-col> -->

          <v-col cols="12">
            <v-divider class="my-4"></v-divider>
          </v-col>
          <v-col cols="12" md="4"><h3>HomePage images</h3></v-col>
          <v-col cols="12" md="8">
            <HomePageImages :themeSetting="themeSetting" @change="onChangeHomePageImages" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="text-end">
        <v-btn @click="submit" :disabled="checkEmptyId()" :loading="isLoadingAction" color="primary" depressed
          >Save</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Favicon from './components/Favicon';
// import Criteo from './components/CriteoOneTag';
import MetaDescription from './components/MetaDescription';
// import GoogleAnalytic from './components/GoogleAnalytic';
// import GoogleAdsTracking from './components/GoogleAdsTracking';
// import FacebookPixel from './components/FacebookPixel';
// import TiktokIdPixel from './components/TiktokIdPixel';
// import Klaviyo from './components/Klaviyo';
import HomePageImages from './components/HomePageImages';

import Scripts from './components/Scripts';
import event from '@/plugins/event-bus';

// import PinterestBing from './components/PinterestBing';
// import PinterestBing from './components/PinterestBing';
// import PasswordProtection from './components/PasswordProtection';
// import PersonalizationPreview from './components/PersonalizationPreview';
import { preferencesApi } from '@/apis/preferences';
import { required, minLength, maxLength, between, email } from 'vuelidate/lib/validators';
import Logo from './components/Logo.vue';
import { settingsApi } from '@/apis/settings';
import { domainService } from '@/apis/domain.s';

export default {
  components: {
    Favicon,
    // Criteo,
    MetaDescription,
    // GoogleAnalytic,
    // GoogleAdsTracking,
    // FacebookPixel,
    // TiktokIdPixel,
    // Klaviyo,
    Scripts,
    // PinterestBing,
    // PasswordProtection,
    // PersonalizationPreview,
    Logo,
    HomePageImages,
  },
  validations: {
    attributes: {
      favIcon: {},
      homePageTitle: {
        maxLength: maxLength(70),
      },
      homePageDescription: {
        maxLength: maxLength(320),
      },
      googleAnalyticsIds: {},
      googleAdsConversionTrack: {},
      facebookPixel: {},
      criteo: {},
      klaviyoApiKey: {},
      addionalScriptHead: {},
      addionalScriptBody: {},
      passwordProtectedEnable: {},
      passwordProtected: {
        maxLength: maxLength(320),
      },
      personalizePreview: {},
      personalizePreviewOption: {},
    },
  },
  data() {
    return {
      loadingData: false,
      attributes: {
        image: '',
        criteo: {
          id: '',
        },
        headerImages: [],
      },
      klaviyoListAndSegment: [],
      klaviyoListAndSegmentSms: [],
      // listSegment: [],
      facebookPixel: [
        {
          id: '',
          token: '',
        },
      ],
      tiktok: [{ pixel_id: '' }],
      mode: '',
      image: {
        src: '',
        position: 1,
      },
      logoImage: {
        src: '',
        position: 1,
      },
      isLoading: false,
      isLoadingAction: false,
      general: {
        storeDetail: {
          name: '',
          accountEmail: '',
          customerEmail: '',
          telegramGroupIds: '',
        },
        language: 'en',
        addressLegalName: '',
        addressPhone: '',
        address: '',
        addressAppartment: '',
        addressCity: '',
        addressCountry: '',
        addressPostalCode: '',
        standardTimezone: '',
        standardUnitWeight: '',
        standardDefaultWeightUnit: '',
        editOrderIdPrfeix: '',
        editOrderIdSuffix: '',
        currency: '',
        currencyFormatHtmlWithoutCurrency: '',
        currencyEmailWithoutCurrency: '',
        blacklistStates: [],
      },
    };
  },
  created() {
    this.loadPreferences();
    this.loadGeneralSettings();
    this.loadThemeSettings();
  },
  methods: {
    checkEmptyId() {
      let check;
      this.facebookPixel.forEach(i => {
        if (i.id === '') {
          check = true;
        } else {
          check = false;
        }
      });
      return check;
    },
    removePixel(index) {
      this.facebookPixel.splice(index, 1);
    },
    addPixel() {
      this.facebookPixel.push({
        id: '',
        token: '',
      });
    },
    removeTikTokPixel(index) {
      this.tiktok.splice(index, 1);
    },
    addTikTokPixel() {
      this.tiktok.push({
        pixel_id: '',
      });
    },
    getImageUpLoad(images) {},
    async loadPreferences() {
      this.isLoading = true;
      try {
        this.loadingData = false;
        let data = await preferencesApi.get();
        this.attributes = data.data;
        console.log(data);
        this.loadingData = true;
        this.facebookPixel = this.attributes.facebookPixel || [];
        this.tiktok = this.attributes.tiktok || [];
        if (this.attributes.favIcon) {
          this.image.src = this.attributes.favIcon;
        }
        if (this.attributes?.logoPath) {
          this.logoImage.src = this.attributes.logoPath;
        }
        if (this.attributes.personalizePreviewOption) {
          this.mode = this.attributes.personalizePreviewOption;
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    async submit() {
      this.attributes.facebookPixel = this.facebookPixel.map(x => x);
      this.attributes.tiktok = this.tiktok.map(x => x);
      this.attributes.personalizePreviewOption = this.mode;
      let googleAdsTracking = this.$refs['google-ads-tracking'];
      if (googleAdsTracking) {
        let conversion = googleAdsTracking.conversion;
        this.attributes.googleAdsConversionTrack = conversion.map(c => c.googleAdsConversionTrack).join(',');
        this.attributes.googleAdsConversionLabel = conversion.map(c => c.googleAdsConversionLabel).join(',');
      }
      this.isLoadingAction = true;
      try {
        await preferencesApi.update(this.attributes);
        event.$emit('getStepStatus', message => {
          this.getStep();
        });

        await settingsApi.updateGeneral(this.general);

        this.isLoadingAction = false;
      } catch (error) {
        this.isLoadingAction = false;
      }
    },
    async loadGeneralSettings() {
      try {
        let { data } = await settingsApi.getGeneral();
        this.general = data;
      } catch (error) {
        console.log(error);
      }
    },
    async loadThemeSettings() {
      try {
        const { data, status } = await domainService.checkEnableRef();
        if (status === 200) {
          this.themeSetting = data[0] || null;
          if (this.themeSetting) {
            const currentValue = this.themeSetting?.pages?.fixed?.header?.block?.find(
              item => item?.component === 'top-bar',
            )?.config[1]?.value;
            const isShowTopBar = this.themeSetting?.pages?.fixed?.header?.block?.find(
              item => item?.component === 'top-bar',
            )?.config[0]?.value;
            this.isShowPriceSaving = !!this.themeSetting?.settings?.product?.layout?.show_price_saving;
            this.isShowTopBar = !!isShowTopBar;
            this.attributes.topbarText = currentValue;
          }
        }
      } catch (error) {
        console.log('🚀 ~ file: HomePageImages.vue:28 ~ getImages ~ error:', error);
      }
    },
    onChangeHomePageImages(images) {
      this.attributes.headerImages = images.filter(item => !!item);
    },
  },
};
</script>
